/* For mobile view */
@media only screen and (max-width: 600px) {
  .header-container {
    flex-direction: column-reverse;
    align-items: center;
  }
  
  .header-logo {
    margin-bottom: 10px;
    max-height: 75px;
  }

  img {
    margin-left: 0;
  }
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-logo {
  max-height: 80px;
}

form {
  font-weight: 700;
  text-align: left;
  display: flex;
  flex-direction: column;
  max-width: 600px;
  margin: 0 auto;
  padding: 40px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
}

h2 {
  font-size: 26px;
}

label {
  font-size: 14px;
  color: #272727;
  margin-top: 15px;
  margin-bottom: 5px;
}

label.checkbox,
div.checkbox {
  font-weight: 400;
  font-size: 13px;
  line-height: 22px;
  padding-top: 7px;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 20;
  /* position: relative; */
}

.Test {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
}

.form-group label {
  display: block;
  padding-right: 10px;
  white-space: nowrap;
}
.group input {
  vertical-align: middle;
}
.group label span {
  vertical-align: middle;
}

.input-group {
  display: flex;
  justify-content: left;
}

.input-field {
  display: flex;
  flex-direction: column;
  margin-left: 0px;
  margin-top: 20px;
  margin-right: 15px;
}

.input-field input {
  margin-top: 5px;
}

input {
  height: 40px;
  padding: 0px 18px;
  font-size: 12px;
  border: 1px solid #ccc;
  border-radius: 2px;
  letter-spacing: 0.2px;
  box-shadow: none !important;
  font-weight: normal;
  font-size: 13px;
}

select {
  height: 40px;
  padding: 0px 18px;
  font-size: 12px;
  border: 1px solid #ccc;
  border-radius: 2px;
  letter-spacing: 0.2px;
  box-shadow: none !important;
  padding: 0 13px;
  cursor: pointer;
  font-weight: normal;
}

option {
  font-weight: normal;
  display: block;
  min-height: 1.2em;
  padding: 0px 2px 1px;
  white-space: nowrap;
}

input [type="text"] {
  min-width: 40px;
}

input[type="submit"] {
  margin-top: 20px;
  background-color: #65b345;
  color: white;
  cursor: pointer;
  border: 1px solid;
  transition: all 0.23s ease-in;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  line-height: 58px;
  font-size: 18px;
  letter-spacing: 0;
  border-radius: 50px;
  height: 60px;
  padding: 0 40px;
  font-weight: 700;
}

input[type="submit"]:hover {
  background-color: #362d64;
}

p {
  font-size: 13px;
  color: #ce5e58;
  margin: 5px 0 0 0;
}

a {
  text-decoration: underline;
}